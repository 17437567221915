import React from "react";

const ProjectAndCamerasCard = ({ projectName, projectCameras }) => {
  const url = process.env.REACT_APP_SHARE_URL;
  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 text-lg-left my-2 mx-2">
        <div className="card">
          <div className="card-body">
            <div className="justify-content-center justify-content-lg-start">
              <h1 className="mt-0 mx-1" style={{ fontSize: "20px" }}>
                {projectName}
              </h1>
            </div>
            <div
              className="d-flex justify-content-center justify-content-lg-start flex-wrap"
              style={{ gap: "8px" }}
            >
              {projectCameras.map((camera, index) => (
                <a
                  key={camera.shareId ?? index}
                  href={`${url}/${camera.shareId}`}
                  className="btn btn-primary"
                  id="cameraLinkButtons"
                >
                  {camera.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAndCamerasCard;
